<script>
import { toRef, ref, inject, computed, watch, onMounted } from "vue";
import FormItem from "./item.vue";
import { useStore } from "vuex";
export default {
  name: "com-base-form",
  components: { FormItem },
  props: ["formProps"],
  setup(props, context) {
    let formSet = toRef(props, "formProps");
    //判断有没有通过props传参,没用的话使用form的注入数据
    if (!formSet.value) {
      formSet = computed(() => {
        return inject("formSet");
      });
    }
    let tableSource = computed(() => {
      return inject("tableSource");
    });

    let store = useStore();
    function init_origin() {
      if (formSet.value && formSet.value.body) {
        formSet.value.body.forEach((b) => {
          if (b.origin && origin.length > 0) {
            store.dispatch(`${b.origin[0]}/${b.origin[1]}`);
          }
        });
      }
    }
    init_origin();
    function form_submit(form) {
      console.log(form, "form提交");
      context.emit("toSearch", form);
      // let sub_data = {}
      // form.items.forEach(item=>{
      //     if(item.value){ sub_data[item.name] = item.value}
      // })
      // if(Object.keys(sub_data).length > 0){
      //     if(form.submit&&form.submit.length>0){
      //         store.dispatch(`${form.submit[0]}/${form.submit[1]}`, sub_data)
      //     }
      // }
    }
    function dialogOpen(body, item) {
      store.commit(`${body.btnClick[0]}/${body.btnClick[1]}`, item);
    }
    function assignment_form(obj) {
      if (Object.keys(obj).length > 0) {
        formSet.value.body.forEach((b) => {
          b.items.forEach((it) => {
            if (obj[it.name]) {
              it.value = obj[it.name];
            }
            if (it.type == "submit") {
              it.label = "保存修改";
            }
          });
        });
      } else {
        formSet.value.body.forEach((b) => {
          b.items.forEach((it) => {
            if (it.name) {
              it.value = "";
            }
            if (it.type == "submit") {
              it.label = "提交保存";
            }
          });
        });
      }
    }

    watch(
      () => tableSource.value,
      (v) => {
        setTimeout(() => {
          if (formSet.value.info) {
            if (typeof v.showInfo == "object" && v.form_assignment) {
              assignment_form(v.showInfo);
            } else {
              assignment_form({});
            }
          }
        }, 100);
      },
      { deep: true }
    );

    return { formSet, form_submit, dialogOpen };
  },
};
</script>
<template>
  <div v-if="formSet" :class="formSet.align=='center'?'row justify-center q-ma-md': ''">
    <!-- <q-form :class="formSet.vertical?'row items-start':''" :style="formSet.style?formSet.style:''">
        <div v-for="(body,index) in formSet.body" :key="body.index" > -->

    <!-- <div :class="body.vertical?'row items-start':''"> -->
    <q-item dense class="q-gutter-x-lg row items-center">
      <div v-for="item in formSet.body[0].items" :key="item.name" class="row items-center q-gutter-r-xs item-width">
        <div class="q-mr-sm label-vue" v-if="item.showlabel"><span v-if="item.required"
            class="text-grey-8 q-mr-xs">*</span>{{item.label}}</div>
        <form-item :form-item="item" @submit="form_submit(formSet.body, item)" />
      </div>
    </q-item>
    <!-- <q-item v-for="item in body.items" :key="item.name" dense>

                    <q-item-section class="text-grey-8" avatar v-if="item.showlabel">
                        <div ><span v-if="item.required" class="text-pink q-mr-xs">*</span>{{item.label}}</div>
                    </q-item-section>
                    <q-item-section>
                        <form-item :form-item="item" @submit="form_submit(body)" @btnClick="dialogOpen(body, item)" :origin="body.origin"/>

                    </q-item-section>
                </q-item> -->
    <!-- </div> -->
    <q-separator v-if="index+1  <formSet.body.length" insert class="q-ma-md" />
    <!-- </div>
    </q-form> -->
  </div>
  <!-- 弹窗1 -->
  <!-- <q-dialog v-model="formSet.body[0].items[5].btnClick">
    <q-card>
        <q-card-section>
            <div class="text-h6">showDialog11111</div>
        </q-card-section>
    </q-card>
</q-dialog> -->
</template>
<style lang='scss' scoped>
:v-deep(.no-wrap) {
  flex-wrap: wrap;
}

.item-width {
  width: 360px;
  height: 24px;
  margin: 3px 0;
}
.label-vue {
  width: 100px;
  text-align: right;
}
:v-deep(.q-field__native) {
  height: 26px;
}
</style>