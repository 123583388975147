<script>
import { ref, toRef, computed, watch } from "vue";
import { useStore, mapGetters } from "vuex";
import ImageCutterCom from "../img/cutter.vue";
export default {
  name: 'com-form-item',
  components: {ImageCutterCom},
  computed: {
      ...mapGetters('page', ['getValidate'])
  },
  props: ['formItem', 'origin'],
  setup(props, context){        
    let node = toRef(props, 'formItem')
    let origin = toRef(props, 'origin')
    let store = useStore()
    let source_origin = computed(()=>{
        if(origin.value&&origin.value.length>2){                
            return store.getters[`${origin.value[0]}/${origin.value[2]}`]
        }else {
            return {}
        }
    })    
    function cutDown(res){
        node.value.value = res.dataURL
        if(node.value.variant&&node.value.variant.auto_submit){
            if(node.value.variant.submit){
                let submit_data = {}
                submit_data[node.value.name] = node.value.value
                store.dispatch(`${node.value.variant.submit[0]}/${node.value.variant.submit[1]}`, submit_data)
            }
        }
    }
    
    //提前加载
    if(node.value.variant&&node.value.variant.origin&&node.value.variant.origin.length>2){
        store.dispatch(`${node.value.variant.origin[0]}/${node.value.variant.origin[2]}`)
    }
    //加载失败select提示
    let fetch_err = computed(()=>{
        let getResponse = store.getters['page/getResponse']
        if(node.value.variant&&node.value.variant.origin&&node.value.variant.origin.length>2){
            return getResponse.error_type==node.value.variant.origin[2] ? 
            `读取失败：${JSON.stringify(getResponse.data)}` : ''
        }else{
            return ''
        }
    })
    //select
    let source_node_origin = computed(()=>{
        if(node.value.variant&&node.value.variant.origin){
          //请求下拉框list接口,获取数据
          return store.getters[`${node.value.variant.origin[0]}/${node.value.variant.origin[1]}`]
        }else{
            return []
        }
    })
    // 接口字段赋值
    let get_select_options = computed(()=>{
        let select_list = []
        if (source_node_origin.value.length>0){
            source_node_origin.value.forEach(it=>{
                select_list.push({
                    label: it[node.value.variant.fields[0]],
                    value: it[node.value.variant.fields[1]]
                })
            })
        } else {
            select_list.push({
                label: '请选择',
                value: ''
            })
        }
        return select_list
    })
    function isOptionSelected(){
        getOptionValue(node.value.value)
    }
    function getOptionValue(val){
        node.value = val // 下拉框选中赋值
        console.log('node.value', node.value, val)
        if(node.value.variant&&node.value.variant.commit){                
            store.dispatch(`${node.value.variant.submit[0]}/${node.value.variant.submit[1]}`, val)
        }
    }
    function submit(){
        context.emit('submit')
    }
    function click_submit(){
        if(node.value.append&&node.value.append.click_submit){                
            submit()
        }
    }
    function keyup() {
        if(node.value.append&&node.value.append.enter){                
            submit()
        }
    }
    return { node,source_origin,cutDown,
        fetch_err,isOptionSelected,getOptionValue, get_select_options, keyup, submit, click_submit,
        date: ref('')
    }
  },    
  watch:{
    source_origin(v){
        if(v&&this.node.auto_assignment){
            this.node.value = v[this.node.name]
        }
    }
  }
};
</script>
<template>
    <div class="com-form-item">
        <q-select v-if="node.type=='select'" :style="{minWidth: node.minWidth}" dense v-model="node.value" :options="get_select_options"
            emit-value :outlined="node.outlined" :filled="node.filled" :label-slot="!node.showlabel"
            map-options @update:model-value ="getOptionValue" @popup-hide="isOptionSelected"
            :bg-color="fetch_err?'warning':''" :options-dense="node.denseOpts"        
            >
            <template v-slot:no-option v-if="fetch_err">
                <q-item>
                    <q-item-section class="text-italic text-negative">
                    {{fetch_err}}
                    </q-item-section>
                </q-item>
            </template>
            <template v-slot:label v-if="!node.showlabel">
                {{ node.label }}
            </template>
        </q-select>

        <q-btn v-if="node.type=='submit'" unelevated :style="{width: node.width}" dense color="primary" :icon="node.variant&&node.variant.icon?node.variant.icon:''"
            :label="node.label?node.label:''" @click="submit()">
        </q-btn>
        <q-avatar size="66px" v-if="node.type=='image'" >
            <q-img  :src="node.value"
            @click="$refs.imagecutter.handleOpen()"/>
            <q-tooltip v-if="node.placeholder">{{node.placeholder}}</q-tooltip>
            <image-cutter-com ref="imagecutter" v-if="node.variant&&node.variant.com&&node.variant.com=='image-cutter'"
            @cutDown="cutDown"/>
        </q-avatar>

        <q-input v-if="node.type=='password'" :style="{width: node.width}" dense  v-model="node.value" :label-slot="!node.showlabel"
         type="password" :outlined="node.outlined" :filled="node.filled" clearable 
        :disable="node.disable" :placeholder="node.placeholder" :name="node.name"
        :error="getValidate.type==node.name" :error-message="getValidate.msg">
            <template v-slot:before v-if="node.before">
            <q-icon :name="node.before" ></q-icon>
            </template>
            <!-- <template v-slot:label v-if="node.showlabel">
                {{ node.label }}
            </template> -->
        </q-input>

        <q-input v-if="node.type=='input'" :style="{width: node.width}" dense  v-model="node.value" :label-slot="!node.showlabel"
            type="text" :outlined="node.outlined" :filled="node.filled" clearable 
            :disable="node.disable" :placeholder="node.placeholder" :name="node.name" autogrow
        >   
            <!-- 无用插槽 -->
            <template v-slot:bottom v-if="getValidate.type==node.name">
                {{ getValidate.msg }}
            </template>
            <template v-slot:before v-if="node.before">
            <q-icon :name="node.before" ></q-icon>
            </template>
            <template v-slot:label v-if="node.showlabel">
                {{ node.label }}
            </template>
        </q-input>

        <div class="textarea-box" v-if="node.type=='textarea'">
            <textarea v-model="node.value" :label-slot="!node.showlabel" :rows=node.rows 
                :cols=node.cols :disable="node.disable" :placeholder="node.placeholder" :name="node.name">
            </textarea>
        </div>

        <q-input v-if="node.type=='unerr-input'" :style="{width: node.width}" dense  v-model="node.value"
         type="text" :outlined="node.outlined" :filled="node.filled" clearable 
        :disable="node.disable" :placeholder="node.placeholder" :name="node.name"
        @keyup.enter="keyup()"
        >
            <template v-slot:before v-if="node.before">
            <q-icon :name="node.before" ></q-icon>
            </template>
            <template v-slot:append v-if="node.append">
                <q-icon :name="node.append.icon" @click="click_submit()">
                    <q-tooltip>{{ node.append.name }}</q-tooltip>
                </q-icon>
            </template>
        </q-input>

        <!-- date -->
        <q-input v-if="node.type=='date'" :style="{width: node.width}" dense v-model="node.value"
        :label-slot="!node.showlabel" type="date" :outlined="node.outlined" :filled="node.filled" clearable
        :disable="node.disable" :placeholder="node.placeholder" :name="node.name">
        </q-input>
        <!-- datetime -->
        <q-input v-if="node.type=='dateTime'" :style="{width: node.width}" dense  v-model="node.value" :outlined="node.outlined" :filled="node.filled" clearable
        :disable="node.disable" :placeholder="node.placeholder" :name="node.name">
        <template v-slot:prepend>
            <q-icon name="fas fa-calendar-day" class="cursor-pointer">
            <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                <q-date v-model="node.value" mask="YYYY-MM-DD HH:mm">
                <div class="row items-center justify-end">
                    <q-btn v-close-popup label="关闭" color="primary" flat />
                </div>
                </q-date>
            </q-popup-proxy>
            </q-icon>
        </template>
        <template v-slot:append>
            <q-icon name="fas fa-clock" class="cursor-pointer">
            <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                <q-time v-model="node.value" mask="YYYY-MM-DD HH:mm" format24h>
                <div class="row items-center justify-end">
                    <q-btn v-close-popup label="关闭" color="primary" flat />
                </div>
                </q-time>
            </q-popup-proxy>
            </q-icon>
        </template>
        </q-input>
    </div>
</template>
<style lang="scss" scoped>
// .com-form-item ::v-deep {
//   .q-field--dense {
//     .q-field__control {
//       height: $inputHeight;
//     }
//     .q-field__marginal {
//       height: $inputHeight;
//       font-size: $tableFontSize;
//     }
//     .q-field__label {
//       top: 50%;
//       font-size: $tableFontSize;
//       transform: translateY(-50%);
//     }
//   }
//   //type为input时的字体高度
//   .q-textarea.q-field--dense .q-field__native {
//     padding-top: 1px;
//     white-space: nowrap;
//     overflow: hidden;
//   }
//   .q-field--auto-height.q-field--dense .q-field__native {
//     min-height: $inputHeight;
//     padding-top: 3px;
//   }
//   // button
//   .q-btn .q-icon {
//     font-size: $tableFontSize;
//   }
//   .q-field--with-bottom {
//     padding-bottom: 0;
//   }

// }
// //select框选中后内容高度
// ::v-deep .q-field--auto-height.q-field--dense .q-field__native{
//   min-height: 24px;
// }
// //select下拉框的高度
// ::v-deep .q-virtual-scroll__content{
//   height: 24px !important;
//   min-height: 24px !important;

// }

//  可设置高度的textarea
.textarea-box {
    width: 250px;
}
textarea {
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    width: 100%;
    height: 100px;
    padding: 4px 12px;
}
textarea:hover {
    border: 1px solid ;
}
textarea:focus {
    outline-color: $primary;
}

</style>